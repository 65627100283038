import React from 'react'
import { useSelector } from 'react-redux'
import { authSelector } from '../authSlice'
import { Alert } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

export function SuperUserBanner() {
  const { userData, orgData } = useSelector(authSelector)

  if (userData?.isSuper !== true) return null

  return (
    <Alert 
      icon={<AdminPanelSettingsIcon />}
      severity="warning"
      sx={{ 
        borderRadius: 0,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '8px',
        backgroundColor: '#fff3e0',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 'regular',
        '& .MuiAlert-message': {
          flex: 1,
          padding: 0,
          fontSize: '0.75rem',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          fontWeight: 'bold'
        },
        '& .MuiAlert-icon': {
          marginRight: 1,
          padding: 0,
          fontSize: '1.25rem',
          color: '#d32f2f'
        }
      }}
    >
      Super User Mode - Logged In As <span style={{ textDecoration: 'underline' }}>{orgData?.companyName || 'Unknown Organization'}</span>
    </Alert>
  )
} 